var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', [_c('Row', {
    attrs: {
      "gutter": 10
    }
  }, [_c('Col', {
    staticClass: "iphone-content",
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 10
    }
  }, [_c('img', {
    staticClass: "demo-model",
    attrs: {
      "src": require("@/assets/icon/iPhoneX.png")
    }
  }), _c('div', {
    staticClass: "model-content"
  }, [_c('iframe', {
    staticClass: "iframe",
    attrs: {
      "scrolling": "auto",
      "frameborder": "0",
      "src": "http://appb.exrick.cn",
      "id": "demo-modal"
    }
  })])]), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 14
    }
  }, [_c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" 说明：全新配套使用Uniapp开发的toB面向企业级App，兼容多端！功能界面完善中... "), _c('a', {
    attrs: {
      "href": "http://xpay.exrick.cn/pay?xboot",
      "target": "_blank"
    }
  }, [_vm._v("立即获取完整版")])]), _c('div', {
    staticClass: "appb-head"
  }, [_c('Poptip', {
    attrs: {
      "trigger": "hover",
      "title": "打开手机扫一扫"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('img', {
    attrs: {
      "src": "https://s2.loli.net/2022/02/22/SqOhGsJawnuL6W9.png",
      "width": "180px"
    }
  })]), _c('Button', {
    staticStyle: {
      "margin-right": "20px"
    },
    attrs: {
      "type": "primary",
      "icon": "md-qr-scanner",
      "shape": "circle"
    }
  }, [_vm._v("手机扫码体验")])], 1), _c('Button', {
    staticStyle: {
      "margin-right": "20px"
    },
    attrs: {
      "to": "http://appb.exrick.cn",
      "target": "_blank",
      "shape": "circle",
      "icon": "md-browsers"
    }
  }, [_vm._v("浏览器打开")]), _c('Button', {
    attrs: {
      "to": "http://xpay.exrick.cn/pay?xboot",
      "target": "_blank",
      "shape": "circle",
      "type": "warning",
      "icon": "ios-paper-plane"
    }
  }, [_vm._v("立即获取源码")])], 1), _c('div', {
    staticClass: "appb-imgs"
  }, [_c('img', {
    attrs: {
      "src": "https://s2.loli.net/2022/02/22/b9PUaTZMFdg7GNQ.png"
    }
  }), _c('img', {
    attrs: {
      "src": "https://s2.loli.net/2022/02/22/pRhoPmi4EaGZkvl.png"
    }
  }), _c('img', {
    attrs: {
      "src": "https://s2.loli.net/2022/02/22/wm1LMbgu9dCXyK6.png"
    }
  })])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };